/* Pied de page */
footer {
    z-index: 99;
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    div {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;

        a {
            margin: 2px 15px;
        }
    }
}

@media (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
}

@media (min-width:600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1200px) {
    /* big landscape tablets, laptops, and desktops */

    footer {
        padding: 5px 0;
        width: 50%;
        position: fixed;
        left: 25%;
        border-radius: 20px 20px 0 0;

    }
}

@media (min-width:1400px) {
    /* hi-res laptops and desktops */
}