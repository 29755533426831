/* Carousel d'image sur page d'accueil */
main .carousel {
    z-index: 1;
    margin: auto;
    border: 2px solid white;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 10px;
    overflow: hidden;
}

.owl-nav,
.owl-dots {
    display: none !important;
}

/* Carousel des news sur page d'accueil */
.news-carousel {
    width: 90% !important;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin: 50px auto;

    div {
        width: 100%;
        background-color: white;
        border-radius: 10px;

        p {
            padding: 20px
        }

        ;

        h2 {
            text-align: center;
            padding: 20px 0 0 0;
        }
    }
}

/* Encart A LA UNE a gauche du carousel de news */
#news-start {
    display: none !important;
}

.video
{
   padding: 0;
}
.video video
{
    // border: 10px solid #004f77;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.phone_shortcut .short img
{
    height: 50px;
}

@media (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
}

@media (min-width:600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

   

    /* Carousel des news sur page d'accueil */
    .news-carousel {
        width: 90% !important;

        div {
            width: auto;
            background-color: inherit;
        }

        .owl-item {
            background-color: white;
        }
    }

}

@media (min-width:1200px) {
    /* big landscape tablets, laptops, and desktops */


    .video video
    {
        width: 80%;
    
    }

    main .carousel {
        width: 60%;
        border-radius: 30px;
    }

    /* Carousel des news sur page d'accueil */
    #news-body {
        display: flex;
        margin: auto;
        justify-content: center;
        margin-top: 50px;

        .news-carousel {
            width: 80% !important;
            height: 80px !important;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%);
            border-radius: 0 15px 15px 0;
            margin: 0;

            .news-item {
                display: flex !important;
                margin: 0;
                justify-content: space-between !important;
                width: 100%;
                height: 100%;
                text-align: center !important;
                align-items: center;

                h5 {
                    width: 100%;
                }
            }

            .owl-item {
                background-color: inherit;
                height: 80px !important;


                img {
                    height: 100% !important;
                    width: auto !important;
                }
            }
        }
    }

    /* Encart A LA UNE a gauche du carousel de news */
    #news-start {
        display: flex !important;
        border: 2px solid white;
        border-radius: 15px 0 0 15px;
        padding: 0 30px;
        color: white;
        display: flex;
        align-items: center;

        p {
            margin: 0;
            font-size: 1.4em;
        }
    }
}

@media (min-width:1600px) {
    /* hi-res laptops and desktops */


    
.phone_shortcut
{
    display: none;
}

}