#main-nav {
    z-index: 200;
    width: 100%;
    border-bottom: 1px solid black;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    #burger {
        display: none;
    }

    #search-social {
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            height: 1.2em;
            margin-bottom: 5px;
        }
    }

    #search-social input {
        border-radius: 15px;
        border: 0.5px solid black !important;
    }

    #logo {
        position: absolute;
        height: 141.2%;
        z-index: 1;
    }

    #logo-phone {
        display: none;
    }

    #items {
        display: flex;
        justify-content: center;

        .item {
            >a {
                padding: 40px 20px 20px 20px;
                display: block;
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 5px;
                color: inherit;
                text-transform: uppercase;
            }

            >a:hover {
                font-weight: bold;
            }

            .sub-item {
                
                width: 100%;
                height: 85vh;
                position: absolute;
                left: 0;
                overflow-y: scroll;
                display: none;
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */
                color: white;
                border-top: 1px solid black;
                border-bottom: 1px solid white;

                .background {
                    width: 30%;
                    position: absolute;
                    display: block;
                    margin: auto;

                    bottom: 0;
                    left: 35%;
                }

                h6 {
                    text-transform: uppercase;
                }

                p {
                    margin: 0em 0;
                }

                a:hover {
                    text-decoration: underline;
                    color: inherit;
                    text-underline-offset: 3px;
                    text-decoration-thickness: 2px;
                }
            }

            .sub-item::-webkit-scrollbar {
                display: none;
                font-weight: bold;
            }
        }
    }
}



@media (max-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (max-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
}

@media (max-width:600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (max-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (max-width:1600px) {
    /* big landscape tablets, laptops, and desktops */

    #main-nav {
        min-height: 60px;

        .sub-item {
            top: 80px;
            padding-bottom: 70px;
        }

        #items {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .item,
        #search-social {
            display: none;
        }

        #burger {
            display: flex;
            align-self: end;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }

        #logo-phone {
            height: 50px;
            display: block;

        }

        #tab-phone {
            display: none !important;

        }

        #search-social {
            padding: 40px 20px 20px 20px;
        }

        .background {
            position: static !important;
            display: block;
            margin: auto;
            width: 100% !important;
        }
    }
}

@media (max-width:1600px) {

    /* hi-res laptops and desktops */
    #main-nav {
        padding: 5px;

        #items .item>a {
            padding: 10px 0 10px 0;
        }

        #search-social {
            display: none;
            padding: 10px 0 10px 0;
        }

        #logo {
            display: none;
        }

        #items {
            justify-content: space-around;
        }

        #tab-phone {
            display: flex;
            align-items: center;
        }

        #tab-phone img {
            height: 50px;
        }
    }
}