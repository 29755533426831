@import "~bootstrap/scss/bootstrap";

body {
    background-color: #004f77;
    font-family: 'Nunito', sans-serif !important;
  

}

/* Liens */
a {
    color: inherit;
    text-decoration: inherit ;
}

a:hover
{
    cursor: url('../../public/images/cursor.ico'), url('../../public/images/cursor.svg'), url('../../public/images/cursor.png'), auto;
    font-weight: bold !important;

}

/* Ligne de séparation */
hr {
    border: 2px solid white;
}

/* Section principale / Corp de page */
main {
    width: 98%;
    height: 98vh;
    background: linear-gradient(180deg, rgba(1, 72, 109, 1) 0%, rgba(11, 84, 120, 1) 0%, rgba(7, 85, 123, 1) 25%, rgba(22, 98, 133, 1) 49%, rgba(78, 147, 169, 1) 75%, rgba(142, 203, 210, 1) 100%);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin: auto;
    border-radius: 20px;
    overflow-y: scroll;
    padding: 150px 20px 20px 20px;
    position: relative;
    z-index: 3;
}

/* Fond vagues de la section principale */
main::after {
    content: "";
    position: fixed;
    top: 0;
    left: 1%;
    background-image: url('../../public/images/main_background.svg');
    width: 98%;
    height: 98vh;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;
    z-index: 0;
    margin: auto;
    pointer-events: none;
    z-index: -1;
}

/* Suppression de la scrollBar vertical */
main::-webkit-scrollbar {
    display: none;
}


.small-text
{
    font-size: 0.8 !important;
}

@media (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
}

@media (min-width:600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1200px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1400px) {
    /* hi-res laptops and desktops */
}