#leftFixed,
#rightFixed {
    display: none;
}


.shortcutDark {
    border: 2px solid #004f77 !important;
}


@media (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width:480px) {
    /* smartphones, Android phones, landscape iPhone */
}

@media (min-width:600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1200px) {
    /* big landscape tablets, laptops, and desktops */


  
}

@media (min-width:1600px) {
    /* hi-res laptops and desktops */


    #leftFixed {
        height: 98vh;
        width: 200px;
        top: 0;
        left: 3%;
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 99;
    }

    #rightFixed {
        height: 98vh;
        width: 150px;
        top: 0;
        right: 3%;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
    }

    #rightFixed img {
        filter: drop-shadow(16px 16px 20px rgb(0, 0, 0));
        width: 100%;
    }

    #shortcut {
        width: 75px;
        border: 2px solid white;
        border-radius: 20px;

        display: block;
    }

    #shortcut .short p {
        background-color: white;
        white-space: nowrap;
        padding: 5px 20px 0 0;
        position: absolute;
        display: flex;
        height: 60px;
        border-radius: 10px;
        text-align: center;
        align-items: center;
        color: black;
        vertical-align: middle;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        display: none;
        margin-bottom: 0;

    }

    #shortcut .short p img {
        width: 75px;
        height: 40px;
        margin-right: 30px;
    }

    #shortcut .short {
        display: flex;
        position: relative;
        align-items: center;
        color: black;
        height: 40px;
        margin: 30px auto;
        z-index: 200;
    }

    #shortcut img {
        margin: auto ;
        height: 100%;
        display: block;
    }
}